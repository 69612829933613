import { Component, Vue, Model } from "vue-property-decorator";
import { phone, pas, phoneCode } from "@/utils/rule";
import * as pickupapi from "@/utils/api";
import headbox from "@/components/head.vue";
import AreaList from "../pickUp/area";
import img from "@/assets/img/大头针.png";
import locationImg from "@/assets/img/检测中心大头针.png";
import pickUp from './components/pickUp.vue'
@Component({
  components: {
    headbox,
    pickUp
  }
})
export default class BindPhoneModel extends Vue {
  // 克重
  public weightbox: any = "";
  // 备注说明
  public intro: any = "";
  /**
   * 定义当前数据
   */
  private RegisterForm: any = {
    loginAccount: "",
    password: "",
    phoneCode: ""
  };
  /**
   * 定义当前表单验证
   */
  private loginRules: any = {
    loginAccount: [
      { required: true, trigger: "blur", validator: phone[0].validator }
    ],
    phoneCode: [
      { required: true, trigger: "blur", validator: phoneCode[0].validator }
    ],
    password: [{ required: true, trigger: "blur", validator: pas[0].validator }]
  };
  public inblockTip = false;
  public isclicktip = false;
  // 发货地址的个人信息
  public address_list: any = 0;
  public address_show = false;
  public areaList: any = AreaList;
  // 收货地址ID
  public formdataId: any = 0;

  public BJreceAddreess = [
    `${Vue.prototype.GLOBAL}（北京天雅）检测中心`,
    "北京市东城区法华寺街136号天雅珠宝城1楼1076"
  ];
  public SZreceAddreess = [
    `${Vue.prototype.GLOBAL}（深圳水贝）检测中心`,
    "广东省深圳市罗湖区田贝3路船舶大厦6楼608"
  ];
  public receAddress = {};
  public isCur = true;

  public hidepop = "hide";
  public bgred = "org";
  public hook = "hide";

  public addresShow = false;
  public radio = '0';
  public Addres = [];


  // 检测中心弹窗选择框事件
  radioClose(item:any){
      this.receAddress = item
      this.addresShow = false
  }
    // 点击遮罩层触发
  overPop(){
      this.addresShow = false
  }
  showAddress() {
    // // 判断本地取货地址是否为当前用户的
    // pickupapi.getuser().then((res:any) => {
    //   const localaddress = localStorage.getItem(`seladdress_${res.uid}`);

    //   // 判断本地取货地址是否为空
    //   if (localaddress != undefined) {
    //     this.receAddress = localaddress.split(",");
    //     // console.log("receAddress" + this.receAddress);
    //     if (this.receAddress[2] == res.uid) {
    //       //已缓存收货地址为深圳，改已选图标
    //       if (this.receAddress[0] === this.SZreceAddreess[0]) {
    //         this.isCur = false;
    //       }
    //     }
    //   }
    // });
  }
  async hidePop() {
    this.addresShow = true
    let res = await pickupapi.querySysAddressList()
    if (res.code == 0) {
        this.Addres = res.data
    }
    
    // this.showAddress();
    // this.hidepop = 'show'? 'hide':'show';
    // if (this.hidepop == "hide") {
    //   this.hidepop = "show";
    // } else {
    //   this.hidepop = "hide";
    // }
  }
  // 预计金额
  public moneybox: any = "";
  public kuaiditype: any = "";
  // 是否有默认的地址
  public address_ = localStorage.getItem("address") || "";
  // 克重计算
  public price = {
    city: {
      base: 12,
      step: 2
    },
    province: {
      base: 13,
      step: 2
    },
    country: {
      base: 23,
      step: 14
    }
  };
  showtip() {
    const than = this;
    than.isclicktip = true;
    setTimeout(() => {
      than.isclicktip = false;
    }, 2000);
  }
  // 生命周期
 mounted() {
    
  
     pickupapi.getAddress().then((res:any)=>{
    if (res.length == 0) {
      this.address_show = false;
    }else if (this.address_) {
      this.address_show = true;
      this.address_list = JSON.parse(this.address_);
    }
      })
    
     pickupapi.querySysAddressList().then((addres:any)=>{
      let receAddress = addres.data.filter((item:any)=>{
        return item.defAddr == true
      })
      this.receAddress = receAddress[0]
     })

    this.inintMap();
    setTimeout(() => {
      // 隐藏下面的logo
      this.getDomlist();
    }, 100);
    // 获取平台默认收货地址
    pickupapi.querySysAddressList().then((res:any) => {
      const resdata = res.data;
      resdata.forEach((element:any) => {
        if (element.defAddr) {
          this.formdataId = element;
          // 判断是否跨区，跨市，跨省
          this.codeCompare(element.code);
        }
      });
    });
  }
  computeExpressPrice(gram:any, type:any) {
    const computePrice = (this.price as any)[type];
    const extKg = Math.round(gram / 100 - 10) / 10;
    return (
      computePrice.base + Math.round(extKg <= 0 ? 0 : extKg * computePrice.step)
    );
  }
  // 跨省比较
  codeCompare(shouhuoID:any) {
    if (this.address_) {
      const shouhuoarr = shouhuoID
        .replace(/\d{1,2}(?=(\d{2})+(\.\d*)?$)/g, "$&,")
        .split(",");
      const fahuoarr = this.address_list.code
        .replace(/\d{1,2}(?=(\d{2})+(\.\d*)?$)/g, "$&,")
        .split(",");
      if (fahuoarr[0] != shouhuoarr[0]) {
        // 跨省
        this.kuaiditype = "country";
      } else if (fahuoarr[1] != shouhuoarr[1]) {
        // 跨市
        this.kuaiditype = "province";
      } else if (fahuoarr[2] != shouhuoarr[2]) {
        // 跨区
        this.kuaiditype = "city";
      }else{
        this.kuaiditype = "city";
      }
    }
  }
  // 预估重量
  weightinput(val:any) {
    if (this.kuaiditype == "") {
      this.weightbox = "";
      return this.$toast.fail("请选择发货地址"), !1;
      // return alert("请选择发货地址"), !1;
    } else {
      this.moneybox = this.computeExpressPrice(val, this.kuaiditype);
      this.inblockTip = true;
    }
  }
  // 立即取件
  postData() {
    if (this.weightbox == "") {
      return this.$toast.fail("预估重量不能为空"), !1;
      // return alert("预估重量不能为空"), !1;
    }
    const postdata = {
      // 收货人地址
      receiveAddrId: String(this.formdataId.id),
      // 发货人地址id
      sendAddrId: String(this.address_list.id),
      // 预估克重
      totalWeightGram: this.weightbox,
      // 预估金额
      expressMoney: String(this.moneybox),
      // 备注
      remark: this.intro
    };

    pickupapi
      .express(postdata)
      .then((res:any) => {
        this.$router.push({ name: "successpage", query: { type: "shangmen" } });
      })
      .catch(() => {
        return this.$toast.fail("网络错误，请稍后再试"), !1;
        // alert("网络错误，请稍后再试");
      });
  }
  getDomlist() {
    const formDOm = document.getElementById("container");
    const childlist = (formDOm as any).childNodes[1].childNodes[1];
    // 删除地图上的logo
    childlist.childNodes[0].remove();
    childlist.childNodes[0].childNodes[0].remove();
  }
  inintMap() {
    //  var map = new qq.maps.Map(document.getElementById("container"), {
    //     // 地图的中心地理坐标。
    //     center: new qq.maps.LatLng(39.916527,116.397128)
    // });
    //定义地图中心点坐标
    const latSZ = process.env.VUE_APP_lATSZ - 0;
    const radSZ =  process.env.VUE_APP_RADSZ - 0;
    const zoom = 3.45;
    const pitch = 40.5;
    const rotation = 0;
// @ts-ignore
    const center = new window.TMap.LatLng(23.325559, 113.274745);
    //定义map变量，调用 window.TMap.Map() 构造函数创建地图
    // @ts-ignore
    const map = new window.TMap.Map(document.getElementById("container"), {
      center: center, //设置地图中心点坐标
      zoom: zoom, //设置地图缩放级别
      pitch: pitch, //设置俯仰角
      rotation: rotation, //设置地图旋转角度
      draggable: false,
      scrollable: false,
      doubleClickZoom: false
    });
    // @ts-ignore
    const labelLayer = new window.TMap.MultiLabel({
      id: "label-layer",
      map: map,
      styles: {
        // @ts-ignore
        labelBigtitle: new TMap.LabelStyle({
          color: "#1C1C1C", //颜色属性
          size: 15, //文字大小属性
          offset: { x: 0, y: 0 }, //文字偏移属性单位为像素
          angle: 0, //文字旋转属性
          alignment: "center", //文字水平对齐属性
          verticalAlignment: "middle" //文字垂直对齐属性
        }),
        // @ts-ignore
        labelSmalltitle: new TMap.LabelStyle({
          color: "#585858", //颜色属性
          size: 12, //文字大小属性
          offset: { x: 40, y: 0 }, //文字偏移属性单位为像素
          angle: 0, //文字旋转属性
          alignment: "center", //文字水平对齐属性
          verticalAlignment: "middle" //文字垂直对齐属性
        })
      },
      geometries: [
        {
          id: "1", //点图形数据的标志信息
          styleId: "labelBigtitle", //样式id
          // @ts-ignore
          position: new TMap.LatLng(latSZ + 9.0, radSZ - 13.5), //点标记坐标位置
          content: process.env.VUE_APP_TESTCENTER, //标注文本
          // "src": "../assets/img/大头针.png",
          properties: {
            //标注点的属性数据
            title: "labelBJ"
          }
        },
        {
          id: "3", //点图形数据的标志信息
          styleId: "labelSmalltitle", //样式id
          // @ts-ignore
          position: new TMap.LatLng(latSZ + 6.5, radSZ - 8), //点标记坐标位置
          content: process.env.VUE_APP_TESTADDRESS, //标注文本
          // "src": "../assets/img/大头针.png",
          properties: {
            //标注点的属性数据
            title: "labelBJ"
          }
        },

      ]
    });
    //创建并初始化MultiMarker
    // @ts-ignore
    const markerLayer = new window.TMap.MultiMarker({
      map: map, //指定地图容器
      //样式定义
      styles: {
        //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
        // @ts-ignore
        myStyle: new TMap.MarkerStyle({
          width: 28, // 点标记样式宽度（像素）
          height: 30, // 点标记样式高度（像素）
          src: locationImg, //图片路径
          //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
          anchor: { x: 32, y: 32 }
        })
      },
      //点标记数据数组
      geometries: [
        {
          //第二个点标记
          id: "1",
          styleId: "myStyle",
          // @ts-ignore
          position: new TMap.LatLng(latSZ, radSZ),
          properties: {
            title: "marker2"
          }
        }
      ]
    });
  }
  hrefbox() {
    // 跳转填写收货地址
    this.$router.push({ name: "address", query: { type: "pickUp" } });
  }

  handleRegister(formName:any) {
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        alert("submit!");
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
}
